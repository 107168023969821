// @flow
import { combineReducers, createStore } from 'redux';
import auth from './auth';
import merchant from './merchant';
import payment from './payment';
import form from './form';
import policy from './policy';
import ui from './ui';

const appReducer = combineReducers({
  auth,
  merchant,
  payment,
  form,
  policy,
  ui,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'CHECKOUT_RESET') {
    state = undefined; // eslint-disable-line
  }

  return appReducer(state, action);
};

// $FlowFixMe
export const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const resetStore = () => ({
  type: 'CHECKOUT_RESET',
});

export default rootReducer;
