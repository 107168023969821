export const errorCodes = {
  TOKEN_AUTHENTICATION_EXPIRED: 'token-authentication-expired',
  CONSUMER_BILL_STATEMENT_OVERDUE: 'consumer-bill-statement-overdue',
  PAYMENT_ORDER_ITEM_FORBIDDEN: 'payment-order-item-forbidden',
  CONSUMER_PHONE_MISMATCH: 'consumer-phone-mismatch',
  TOKEN_AUTHENTICATION_FAILED: 'token-authentication-failed',
  PAYMENT_AUTHENTICATION_FAILED: 'payment-authentication-failed',
  CONSUMER_EMAIL_MISMATCH: 'consumer-email-mismatch',
  REJECTED: 'rejected',
  TOKEN_REJECTED: 'token-rejected',
  HOUKATSU_PREVIOUSLY_REJECTED: 'consumer-previously-rejected',
  KYC_REQUIRED: 'kyc-required',
};

export const errorCodecV2 = {
  OVERDUE_BILL: 'rejected.overdue-bill',
  EXCEEDED_PLUS_LIMIT: 'rejected.exceeded-plus-limit',
  EXCEEDED_PLUS_LIMIT_RETRIABLE: 'rejected.exceeded-plus-limit-retriable',
  MERCHANT_REQUIRES_KYC: 'rejected.merchant-requires-kyc',
  MERCHANT_REQUIRES_HOSHINO: 'rejected.merchant-requires-kyc-hoshino',
  GENERIC: 'rejected.generic',
  EXCEEDED_NLP_LIMIT_RETRIABLE: 'rejected.exceeded-nlp-limit-retriable',
  EXCEEDED_NLP_LIMIT: 'rejected.exceeded-nlp-limit',
  EXCEEDED_NLP_LIMIT_RETRIABLE_X1: 'rejected.exceeded-nlp-limit-retriable-x1',
  EXCEEDED_NLP_LIMIT_X1: 'rejected.exceeded-nlp-limit-x1',

  // PLUS UPGRADE
  HOUKATSU_REJECTED: 'rejected.hokatsu-rejected',
  PLUS_UPGRADE_KYC_REJECTED: 'plus-upgrade.kyc-rejected',
  PLUS_UPGRADE_TIMEOUT: 'plus-upgrade.timeout',
  PLUS_UPGRADE_OUTSIDE_CIC_HOURS: 'plus-upgrade.outside-cic-hours',
};

export default {
  errorCodes,
  errorCodecV2,
};
