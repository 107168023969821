'use strict';
import React from 'react';

import { errorCodecV2, errorCodes } from '../../constants/error-codes';
import { onClose } from '../../utils';
import styles from './Error.scss';
import { MixpanelHelpers, MIXPANEL_ACTION_LINK_CLICK } from '../../utils/mixpanel';

const errorScreenContentMobile = {
  [errorCodecV2.OVERDUE_BILL]: {
    body: [
      <b data-testid="error-screen-title" key="title" className={styles.title}>
        ペイディの決済を承認することができませんでした。
      </b>,
      <p key="desc-line1" className={styles['description']}>
        お支払い待ちのご請求があるため、ペイディの決済を承認することができませんでした。
      </p>,
      <br key={'br'} />,
      <p key="desc-line2" className={`${styles.description} ${styles['description-last']}`}>
        下記のボタンからMyPaidyに進み、案内に従ってお支払い手続きを行ってください。
      </p>,
    ],
    buttonText: '今すぐ支払う',
    buttonHref: process.env.ONE_LINK_OVERDUE_BILL,
    onClick: () => {
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.OVERDUE_BILL}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Pay bill',
        },
      });
    },
    hasFooter: true,
    hideShopInfo: true,
    footerText: '他の決済方法を選ぶ',
    footerOnClick: () => {
      onClose(false);
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.OVERDUE_BILL}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Other payment method',
        },
      });
    },
  },
  [errorCodes.CONSUMER_BILL_STATEMENT_OVERDUE]: {
    buttonText: '今すぐ支払う',
    buttonHref: process.env.ONE_LINK_OVERDUE_BILL,
    onClick: () => {
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.OVERDUE_BILL}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Pay bill',
        },
      });
    },
    hasFooter: true,
    hideShopInfo: true,
    footerText: '他の決済方法を選ぶ',
    footerOnClick: () => {
      onClose(false);
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.OVERDUE_BILL}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Other payment method',
        },
      });
    },
  },
  [errorCodecV2.EXCEEDED_PLUS_LIMIT]: {
    body: [
      <div key="img-container" className={styles['sorry-container']}>
        <img
          key="img"
          src={require('../../assets/sorry.svg')}
          alt="sorry"
          width={184}
          height={184}
        />
      </div>,
      <b data-testid="error-screen-title" key="title" className={styles.title}>
        大変申し訳ございませんが、ペイディの決済を承認することができませんでした。
      </b>,
      <p key="desc-line1" className={styles['description']}>
        ペイディのご利用可能枠を超過しているため、決済が承認されませんでした。
      </p>,
      <br key={'br'} />,
      <p key="desc-line2" className={`${styles.description} ${styles['description-last']}`}>
        なお、今月のご利用可能枠は、ペイディアプリよりご確認いただけます。
      </p>,
    ],
    onClick: () => {
      onClose(false);
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.EXCEEDED_PLUS_LIMIT}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Other payment method',
        },
      });
    },
    buttonText: '他の決済方法を選ぶ',
    hasFooter: true,
    footerText: 'アプリを開く',
    footerOnClick: () => {
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.EXCEEDED_PLUS_LIMIT}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Open App text link',
        },
      });
    },
    footerHref: process.env.ONE_LINK_OVER_PLUS_LIMIT_CANNOT_RETRY,
    hideShopInfo: true,
  },
  [errorCodecV2.EXCEEDED_PLUS_LIMIT_RETRIABLE]: {
    body: [
      <b data-testid="error-screen-title" key="title" className={styles.title}>
        大変申し訳ございませんが、ペイディの決済を承認することができませんでした。
      </b>,
      <p key="desc-line1" className={styles.description}>
        ペイディのご利用可能枠を超過しているため、決済を承認することができませんでした。
      </p>,
      <p key="desc-line2" className={`${styles.description} ${styles['description-last']}`}>
        お手数ですが、下記のボタンをタップして、店舗が提供する他の決済方法を選択してください。
      </p>,
      <div key="close-btn" className={styles['controls-space']}>
        <button
          className={`${styles.button} ${styles['button-space']}`}
          onClick={() => {
            MixpanelHelpers.trackAction({
              pathname: `/error/${errorCodecV2.EXCEEDED_PLUS_LIMIT_RETRIABLE}`,
              actionName: MIXPANEL_ACTION_LINK_CLICK,
              extraData: {
                Type: 'Other payment method',
              },
            });
            onClose(false);
          }}
          data-testid="btn-close"
        >
          他の決済方法を選ぶ
        </button>
      </div>,
      <p key="desc-line3" className={styles['description-last']}>
        {[
          'なお、ペイディアプリから「すぐ払い」機能を利用して、コンビニでご請求金額をお支払いいただくことで、ご利用いただけるようになる場合がございます。詳細なご利用方法は、',
          <a
            key="こちら"
            target="_blank"
            rel="noopener noreferrer"
            href="https://cs-support.paidy.com/support/solutions/articles/150000040753"
            onClick={() => {
              MixpanelHelpers.trackAction({
                pathname: `/error/${errorCodecV2.EXCEEDED_PLUS_LIMIT_RETRIABLE}`,
                actionName: MIXPANEL_ACTION_LINK_CLICK,
                extraData: {
                  Type: 'FAQ',
                },
              });
            }}
          >
            こちら
          </a>,
          'をご確認ください。',
        ]}
      </p>,
      <img
        key="btm-img"
        className={styles['bottom-image']}
        src={require('../../assets/exceeded-plus-limit-retriable-mobile.png')}
        alt="retriable"
      />,
      <div key="close-link" className={styles['controls-space']}>
        <a
          className={`${styles.button} ${styles['button-link']} ${styles['button-dark']} ${styles['button-space-dark']}`}
          target="_blank"
          data-testid="btn-link"
          href={process.env.ONE_LINK_OVER_PLUS_LIMIT_CAN_RETRY}
          rel="noreferrer"
          onClick={() => {
            MixpanelHelpers.trackAction({
              pathname: `/error/${errorCodecV2.EXCEEDED_PLUS_LIMIT_RETRIABLE}`,
              actionName: MIXPANEL_ACTION_LINK_CLICK,
              extraData: {
                Type: 'PayNow',
              },
            });
          }}
        >
          アプリを開いてコンビニで支払う
        </a>
      </div>,
    ],
    hasFooter: false,
    hideShopInfo: true,
    hasFooterDesktop: false,
  },
  [errorCodecV2.MERCHANT_REQUIRES_KYC]: {
    body: [
      <b data-testid="error-screen-title" key="title" className={styles.title}>
        大変申し訳ございませんが、ペイディの決済を承認することができませんでした。
      </b>,
      <p key="desc-line1" className={styles['description']}>
        ご利用の加盟店でペイディを利用するには、本人確認が必要です。お客様は本人確認が完了していないため、決済を承認することができませんでした。
      </p>,
      <br key="br1" />,
      <p key="desc-line2" className={styles['description']}>
        本人確認後はお客様のご利用可能枠が確認可能になり、その範囲内でぺイディをご利用いただけます。
      </p>,
      <br key="br2" />,
      <p key="desc-line3" className={`${styles.description} ${styles['description-last']}`}>
        大変お手数をおかけいたしますが、ペイディアプリから本人確認後、もう一度お試しいただけますと幸いです。
      </p>,
      <img
        key="btm-img"
        className={styles['bottom-image']}
        src={require('../../assets/hoshino-kyc-required.png')}
        alt="hoshino"
      />,
    ],
    buttonText: 'アプリから本人確認する',
    buttonHref: process.env.ONE_LINK_MERCHANT_REQUIRES_KYC,
    onClick: () => {
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.MERCHANT_REQUIRES_KYC}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'eKYC',
        },
      });
    },
    hasFooter: true,
    footerText: '他の決済方法を選ぶ',
    footerOnClick: () => {
      onClose(false);
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.MERCHANT_REQUIRES_KYC}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Other payment method',
        },
      });
    },
    hideShopInfo: true,
  },
  [errorCodecV2.MERCHANT_REQUIRES_HOSHINO]: {
    body: [
      <b data-testid="error-screen-title" key="title" className={styles.title}>
        大変申し訳ございませんが、ペイディの決済を承認することができませんでした。
      </b>,
      <p key="desc-line1" className={styles['description']}>
        ご利用の加盟店でペイディを利用するには、本人確認が必要です。お客様は本人確認が完了していないため、決済を承認することができませんでした。
      </p>,
      <br key="br1" />,
      <p key="desc-line2" className={styles['description']}>
        本人確認後はお客様のご利用可能枠が確認可能になり、その範囲内でぺイディをご利用いただけます。
      </p>,
      <br key="br2" />,
      <p key="desc-line3" className={`${styles.description} ${styles['description-last']}`}>
        大変お手数をおかけいたしますが、ペイディアプリから本人確認後、もう一度お試しいただけますと幸いです。
      </p>,
      <img
        key="btm-img"
        className={styles['bottom-image']}
        src={require('../../assets/hoshino-kyc-required.png')}
        alt="hoshino"
      />,
    ],
    buttonText: 'アプリから本人確認する',
    buttonHref: process.env.ONE_LINK_HOSHINO,
    onClick: () => {
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.MERCHANT_REQUIRES_HOSHINO}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'eKYC',
        },
      });
    },
    hasFooter: true,
    footerText: '他の決済方法を選ぶ',
    footerOnClick: () => {
      onClose(false);
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.MERCHANT_REQUIRES_HOSHINO}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Other payment method',
        },
      });
    },
    hideShopInfo: true,
  },
  [errorCodecV2.GENERIC]: {
    body: [
      <div key="img-container" className={styles['sorry-container']}>
        <img
          key="img"
          src={require('../../assets/sorry.svg')}
          alt="sorry"
          width={184}
          height={184}
        />
      </div>,
      <b data-testid="error-screen-title" key="title" className={styles.title}>
        大変申し訳ございませんが、ペイディの決済を承認することができませんでした。
      </b>,
      <p key="desc-line1" className={`${styles['description']} ${styles['bottom-space-24']}`}>
        お手数ですが、店舗が提供する他の決済方法を選択してください。
      </p>,
      <p key="desc-line2" className={`${styles.description} ${styles['bottom-space-24']}`}>
        ペイディでは、すべてのお客様に安心・安全にご利用いただける健全なサービスの運営を目指しており、その取り組みのひとつとして、ご注文ごとにご利用内容の確認を行っております。そのため、店舗や商品などのご注文内容によって、決済が承認されない場合があります。
      </p>,
      <p key="desc-line3" className={`${styles.description} ${styles['description-last']}`}>
        なお、詳細な審査基準に関しましてはお答えすることができません。ご迷惑をおかけして誠に申し訳ございませんが、何卒ご理解いただけますと幸いです。
      </p>,
    ],
    onClick: () => {
      onClose(false);
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.GENERIC}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Other payment method',
        },
      });
    },
    buttonText: '他の決済方法を選ぶ',
    hasFooter: false,
    hideShopInfo: true,
  },
  [errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE]: {
    body: [
      <b data-testid="error-screen-title" key="title" className={styles.title}>
        大変申し訳ございませんが、ペイディの決済を承認することができませんでした。
      </b>,
      <p data-testid="error-screen-description" key="desc-line1" className={styles['description']}>
        ペイディのご利用可能枠を超過しているため、決済を承認することができませんでした。
      </p>,
      <p
        data-testid="error-screen-description"
        key="desc-line2"
        className={`${styles.description} ${styles['description-last']}`}
      >
        お手数ですが、下記のボタンをタップして、店舗が提供する他の決済方法を選択してください。
      </p>,
      <div key="close-btn" className={styles['controls-space']}>
        <button
          className={`${styles.button} ${styles['button-space']}`}
          onClick={() => {
            onClose(false);
            MixpanelHelpers.trackAction({
              pathname: `/error/${errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE}`,
              actionName: MIXPANEL_ACTION_LINK_CLICK,
              extraData: {
                Type: 'Other payment method',
              },
            });
          }}
          data-testid="btn-close"
        >
          他の決済方法を選ぶ
        </button>
      </div>,
      <p
        data-testid="error-pay-now-description"
        key="desc-line3"
        className={styles['description-last']}
      >
        なお、ペイディアプリから下記の機能を利用することで、ご利用いただけるようになる場合がございます。
      </p>,
      <div className={styles['bulleted-item']} key="npay">
        <img src={require('../../assets/bulleted-dark.svg')} alt="bulleted icon" />
        <span>今月のご利用分を3・6・12回あと払いに変更する</span>
      </div>,
      <div className={`${styles['bulleted-item']} ${styles['bulleted-item-last']}`} key="pay-now">
        <img src={require('../../assets/bulleted-dark.svg')} alt="bulleted icon" />
        <span>すぐ払い機能でご請求金額を支払う</span>
      </div>,
      <p key="description" className={`${styles['description-last']} ${styles['space-top-16']}`}>
        {[
          '詳細なご利用方法は、',
          <a
            key="こちら"
            target="_blank"
            rel="noopener noreferrer"
            href="https://cs-support.paidy.com/support/solutions/articles/150000040638-3%E3%83%BB6%E3%83%BB12%E5%9B%9E%E3%81%82%E3%81%A8%E6%89%95%E3%81%84%E3%81%AE%E5%88%A9%E7%94%A8%E6%96%B9%E6%B3%95%E3%81%8C%E7%9F%A5%E3%82%8A%E3%81%9F%E3%81%84"
            onClick={() => {
              MixpanelHelpers.trackAction({
                pathname: `/error/${errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE}`,
                actionName: MIXPANEL_ACTION_LINK_CLICK,
                extraData: {
                  Type: 'FAQ',
                },
              });
            }}
          >
            こちら
          </a>,
          'をご確認ください。',
        ]}
      </p>,
      <img
        key="btm-img"
        className={styles['bottom-image']}
        src={require('../../assets/exceeded-nlp-limit-retriable.png')}
        alt="retriable"
      />,
      <div key="close-link" className={styles['controls-space']}>
        <a
          className={`${styles.button} ${styles['button-link']} ${styles['button-dark']} ${styles['button-space-dark']}`}
          target="_blank"
          data-testid="btn-close-link"
          href={process.env.ONE_LINK_CHECKOUT_EXCEEDED_NLP_LIMIT_RETRIABLE}
          rel="noreferrer"
          onClick={() => {
            MixpanelHelpers.trackAction({
              pathname: `/error/${errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE}`,
              actionName: MIXPANEL_ACTION_LINK_CLICK,
              extraData: {
                Type: 'Open App CTA button',
              },
            });
          }}
        >
          アプリを開く
        </a>
      </div>,
    ],
    buttonHref: process.env.ONE_LINK_CHECKOUT_EXCEEDED_NLP_LIMIT_RETRIABLE,
    hasFooter: false,
    hideShopInfo: true,
  },
  [errorCodecV2.EXCEEDED_NLP_LIMIT]: {
    body: [
      <div key="img-container" className={styles['sorry-container']}>
        <img
          key="img"
          src={require('../../assets/sorry.svg')}
          alt="sorry"
          width={184}
          height={184}
        />
      </div>,
      <b data-testid="error-screen-title" key="title" className={styles.title}>
        大変申し訳ございませんが、ペイディの決済を承認することができませんでした。
      </b>,
      <p key="desc-line1" className={styles['description']}>
        ペイディのご利用可能枠を超過しているため、決済が承認されませんでした。
      </p>,
      <br key={'br'} />,
      <p key="desc-line2" className={`${styles.description} ${styles['description-last']}`}>
        なお、今月のご利用可能枠は、ペイディアプリよりご確認いただけます。
      </p>,
    ],
    onClick: () => {
      onClose(false);
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.EXCEEDED_NLP_LIMIT}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Other payment method',
        },
      });
    },
    buttonText: '他の決済方法を選ぶ',
    hasFooter: true,
    footerText: 'アプリを開く',
    footerHref: process.env.ONE_LINK_CHECKOUT_EXCEEDED_NLP_LIMIT,
    footerOnClick: () => {
      MixpanelHelpers.trackAction({
        pathname: `/error/${errorCodecV2.EXCEEDED_NLP_LIMIT}`,
        actionName: MIXPANEL_ACTION_LINK_CLICK,
        extraData: {
          Type: 'Open App text link',
        },
      });
    },
    hideShopInfo: true,
  },
  [errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE_X1]: {
    title: 'ペイディの決済が承認されませんでした',
    description: [
      'ペイディのご利用可能額を超過しています。',
      [
        'ペイディアプリから',
        <a
          key="すぐ払い"
          href={process.env.ONE_LINK_CHECKOUT_OVER_NLP_LIMIT_CAN_RETRY_1X}
          target="_blank"
          rel="noopener noreferrer"
        >
          すぐ払い,
        </a>,
        "機能でご請求金額をお支払いいただくと、ご利用いただけるようになる場合があります。'",
      ],
      'ペイディアプリからすぐ払い機能でご請求金額をお支払いいただくと、ご利用いただけるようになる場合があります。',
    ],
    noteText: [
      '※すぐ払いはコンビニ払いのみご利用可能です。',
      '※毎月のご請求のお支払い方法を「口座振替」に設定している場合、すぐ払いをご利用いただけません。お支払方法を「コンビニ払い・銀行振込」に変更後、コンビニですぐ払いをご利用ください。',
    ],
    buttonText: 'すぐ払いで支払う',
    buttonHref: process.env.ONE_LINK_CHECKOUT_OVER_NLP_LIMIT_CAN_RETRY_1X,
    hasFooter: true,
    hasFooterDesktop: false,
    hideShopInfo: false,
  },
  [errorCodecV2.EXCEEDED_NLP_LIMIT_X1]: {
    title: 'ペイディの決済が承認されませんでした',
    description: [
      'ペイディの今月のご利用可能枠を超過しています。',
      '今月のご利用可能枠は、ペイディアプリでご確認いただけます。',
    ],
    buttonText: '今すぐアプリで確認する',
    hasFooter: false,
    buttonHref: process.env.ONE_LINK_CHECKOUT_OVER_NLP_LIMIT_CANNOT_RETRY_1X,
    hideShopInfo: false,
  },
  [errorCodecV2.HOUKATSU_REJECTED]: {
    body: [
      <div key="img-container" className={styles['sorry-container']}>
        <img
          key="img"
          src={require('../../assets/sorry.svg')}
          alt="sorry"
          width={184}
          height={184}
        />
      </div>,
      <b data-testid="error-screen-title" key="title" className={`${styles.title} ${styles['text-align-center']}`}>
        本人確認の結果について
      </b>,
      <p key="desc-line1" className={styles['description']}>
        ペイディでは、すべてのお客様に安心・安全にご利用いただける健全なサービスの運営を目指しており、その取り組みのひとつとして、審査を行っております。今回、お客様にお手続きいただいた内容をもとに検討を行いましたが、誠に残念ながら、今回のご利用は見送りとさせていただくこととなりました。お客様のご期待にそえず、誠に申し訳ございません。
      </p>,
      <br key={'br'} />,
      <p key="desc-line2" className={`${styles.description} ${styles['description-last']}`}>
        お手数ですが、店舗が提供する他の決済方法をご利用ください。
      </p>,
    ],
    onClick: () => onClose(false),
    buttonText: '他の決済方法を選ぶ',
    hasFooter: false,
    hideShopInfo: true,
  },
  [errorCodecV2.PLUS_UPGRADE_KYC_REJECTED]: {
    body: [
      <div key="img-container" className={styles['sorry-container']}>
        <img
          key="img"
          src={require('../../assets/retry.svg')}
          alt="retry"
          width={184}
          height={184}
        />
      </div>,
      <b data-testid="error-screen-title" key="title" className={`${styles.title} ${styles['text-align-center']}`}>
        再度本人確認の手続きを<br key={'br'} />
        お願いします
      </b>,
      <p key="desc-line1" className={styles['description-last']}>
        本人確認の手続きを行っていただき、誠にありがとうございます。大変申し訳ございませんが、下記のような理由により、本人確認ができませんでした。
      </p>,
      <ul className={styles.ul}>
        <li>
          身分証明書の情報が入力情報と一致しない
        </li>
        <li>
          身分証明書の厚みが不自然
        </li>
        <li>
          身分証明証の情報が読み取れない など
        </li>
      </ul>,
      <p key="desc-line2" className={`${styles.description} ${styles['description-last']}`}>
        お手数ではございますが、再度お試しください。
      </p>,
    ],
    onClick: () => onClose(false),
    buttonText: '再度試す',
    hasFooter: false,
    hideShopInfo: true,
  },
  [errorCodecV2.PLUS_UPGRADE_TIMEOUT]: {
    body: [
      <div key="img-container" className={styles['sorry-container']}>
        <img
          key="img"
          src={require('../../assets/clock-timer@3x.png')}
          alt="clock-timer"
          width={184}
          height={184}
        />
      </div>,
      <b data-testid="error-screen-title" key="title" className={`${styles.title} ${styles['text-align-center']}`}>
       セッションの有効期限が切れました
      </b>,
      <p key="desc-line1" className={`${styles['description']} ${styles['text-align-center']}`}>
        お手数おかけしますが、<br />
        もう一度はじめからお試しください。
      </p>,
    ],
    onClick: () => onClose(false),
    buttonText: 'もう一度はじめから試す',
    hasFooter: false,
    hideShopInfo: true,
  },
  [errorCodecV2.PLUS_UPGRADE_OUTSIDE_CIC_HOURS]: {
    body: [
      <div key="img-container" className={styles['sorry-container']}>
        <img
          key="img"
          src={require('../../assets/clock-timer@3x.png')}
          alt="clock-timer"
          width={184}
          height={184}
        />
      </div>,
      <b data-testid="error-screen-title" key="title" className={`${styles.title} ${styles['text-align-center']}`}>
        ペイディの決済が完了できません
      </b>,
      <p key="desc-line1" className={styles['description']}>
        大変申し訳ございませんが、翌朝8:00までペイディの本人確認の結果の確認ができないため、ペイディの決済が完了できません
      </p>,
      <br key={'br'} />,
      <p key="desc-line2" className={`${styles.description} ${styles['description-last']}`}>
        翌朝8:00以降に本人確認の結果を確認後、改めてペイディでの購入をお試しいただくか、他の決済方法を選択してください。
      </p>,
    ],
    onClick: () => onClose(false),
    buttonText: '他の決済方法を選ぶ',
    hasFooter: false,
    hideShopInfo: true,
  },
};

export default errorScreenContentMobile;
