// @flow
import React from 'react';
import { useLocation } from 'react-router-dom';

import { absoluteURL } from '../../utils';
import styles from './Frame.scss';
import FrameNavigation from './FrameNavigation';

type Props = {
  hasCornerBackButton: ?boolean,
  hasCornerCloseButton: ?boolean,
  token: ?string,
  totalAmount: ?number,
  numberOfInstallments: ?number,
  monthlyAmount: ?number,
  showInstallments: ?boolean,
  shopName: ?string,
  logoURL: ?string,
  hideShopInfo?: boolean,
};

const FrameHeader = (props: Props) => {
  const {
    // from parent
    hasCornerBackButton,
    hasCornerCloseButton,
    showInstallments,
    // from redux
    token,
    totalAmount,
    numberOfInstallments,
    monthlyAmount,
    shopName,
    logoURL,
    hideShopInfo = false,
  } = props;

  const { pathname } = useLocation();

  return (
    <header data-hide-shop-info={hideShopInfo}>
      <FrameNavigation
        hasCornerBackButton={hasCornerBackButton}
        hasCornerCloseButton={hasCornerCloseButton}
      />

      {pathname === '/' && (
        <div className={styles.introLogoContainer}>
          <img src={require('../../assets/paidy-logo.svg')} alt="Paidy logo" />
        </div>
      )}

      {pathname !== '/' && !hideShopInfo && (
        <div className={styles.shopInfoContainer}>
          <b className={styles.merchantName} data-testid="frame-header-merchant-name">
            {shopName}
          </b>
          {logoURL && absoluteURL(logoURL) && (
            <div
              className={styles.merchantLogo}
              style={{ backgroundImage: `url(${absoluteURL(logoURL)})` }}
              data-testid="frame-header-merchant-logo"
            />
          )}
          {!token && totalAmount && (
            <span className={styles.amount}>
              {showInstallments ? (
                <>
                  <b className={styles.amountNumber} data-testid="frame-header-monthly-amount">
                    {monthlyAmount.toLocaleString('ja-jp')}
                  </b>
                  <b data-testid="frame-header-amount-unit">円/月 × {numberOfInstallments}回</b>
                </>
              ) : (
                <>
                  <b className={styles.amountNumber} data-testid="frame-header-total-amount">
                    {totalAmount.toLocaleString('ja-jp')}
                  </b>
                  <b data-testid="frame-header-amount-unit">円</b>
                </>
              )}
            </span>
          )}
        </div>
      )}
    </header>
  );
};

export default FrameHeader;
