// @flow
/* eslint-disable camelcase */
import React, { Component } from 'react';
import autobind from 'autobind-decorator';
import idx from 'idx';
import debounce from 'lodash.debounce';
import * as Sentry from '@sentry/browser';
import { MemoryRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
// components
import ErrorBoundary from '../Error/ErrorBoundary';
import LoginForm from '../LoginForm/LoginFormContainer';
import OTPInput from '../OTP/OTPInputContainer';
import Multipay from '../MultiPay/MultiPayContainer';
import Threepay from '../ThreePay/ThreePay';
import Result from '../Result/Result';
import Intro from '../../components/Intro/Intro';
import TOC from '../TOC/TOCContainer';
import Help from '../Help/Help';
import Plus from '../Plus/Plus';
import Digital from '../Digital/Digital';
import TokenConfirm from '../Token/TokenConfirmContainer';
import Error from '../Error/Error';
import FirstScreen from '../FirstScreen/FirstScreenContainer';
import Big from '../Big/Big';
import BigSingle from '../Big/BigSingleContainer';
import HighTicket from '../HighTicket/HighTicket';
import PlusUpgradePending from '../PlusUpgradePending/PlusUpgradePending';
// utils
import PaymentAPI from '../../payment-api';
import {
  getParsedPhoneNumber,
  isToken,
  normalizeMetadata,
  isLocalStorageSupported,
  isWebView,
  isWithinCICBusinessHours,
} from '../../utils';
import robustStorage from '../../utils/robust-local-storage';
import { loadingTypes, paymentStatusMap, SELECTION_OPTION_PHASE } from '../../constants';
import {
  MixpanelHelpers,
  MIXPANEL_ACTION_APP_CLOSE,
  MIXPANEL_ACTION_APP_OPEN,
  URL_TO_SCREEN,
} from '../../utils/mixpanel';
import { errorCodecV2 } from '../../constants/error-codes';

// styles
import styles from './App.scss';

Sentry.init({
  dsn: 'https://0ee817ed784e4987bfdd5770d82866f3@o56970.ingest.sentry.io/1296498',
  beforeSend: event => {
    if (event.extra.__serialized__.data.code === 'token.authentication.expired') {
      // Return null no to send event to SENTRY
      return null;
    }

    return Promise.resolve(event);
  },
});
MixpanelHelpers.initialize();

let PA;
let apiKey: string;
let apiHost: string;

const onGetCodeThruVoice = (): Promise<any> => {
  return PA.challenge({
    auth_challenge_dispatch_method: 'voice',
  });
};

const VIEWPORT_REGIST_WAIT_MS = 250;
const updateViewportOrientation = debounce(
  MixpanelHelpers.registerViewportOrientation,
  VIEWPORT_REGIST_WAIT_MS
);

const ERROR_WRONG_PINCODE: string = 'payment.authentication.failed';

type Props = {
  email: ?string,
  phone: ?string,
  tier: ?string,
  // totalAmount: ?number,
  configuration: ?Object,
  patch: ?Object,
  updateAuthPairs: Function,
  updateMerchantPairs: Function,
  updatePaymentPairs: Function,
  updateFormPairs: Function,
  updateUIPairs: Function,
  resetStore: Function,
  history: Object,
  location: Object,
  // from merchant
  merchantConfig: Object,
  merchantPayload: Object,
  // ui
  pinCodeSentCount: number,
};

type State = {
  payload: Object,
  isNumberOfInstallmentSelected: boolean,
};

let localRememberMe;

if (isLocalStorageSupported()) {
  const cachedRememberMe = robustStorage.getItem('paidy_remember_me');

  if (cachedRememberMe === 'true') {
    // if the user already consented on rememberMe
    localRememberMe = true;

    // set a flag so we can easily recognize if the user is a returning user
    MixpanelHelpers.registerReturningUserStatus(true);
    MixpanelHelpers.registerRememberMeStatus(true);
  } else if (cachedRememberMe === null) {
    // if never set, set rememberMe = false by default
    localRememberMe = false;
    robustStorage.setItem('paidy_remember_me', 'false');

    MixpanelHelpers.unregisterSuperProperties(['Returning User', 'Is User Remembered']);
  } else {
    localRememberMe = false;
    MixpanelHelpers.registerReturningUserStatus(false);
    MixpanelHelpers.registerRememberMeStatus(false);
  }
} else {
  // localStorage is unavailable
  localRememberMe = false;
  MixpanelHelpers.unregisterSuperProperties(['Returning User', 'Is User Remembered']);
}

class App extends Component<Props, State> {
  // eslint-disable-next-line
  confirmToken: Function;
  patchToken: Function;

  @autobind
  onUnload() {
    MixpanelHelpers.trackAction({
      customPath: 'Checkout',
      actionName: MIXPANEL_ACTION_APP_CLOSE,
      extraData: {
        'Current Page': URL_TO_SCREEN[this.props.location.pathname],
      },
    });
    MixpanelHelpers.trackDuration({
      customPath: 'Checkout',
      actionName: MIXPANEL_ACTION_APP_OPEN,
      shouldEndTracker: true,
    });
  }

  constructor(props: Props) {
    console.log('App re-constructed.');
    super(props);

    props.resetStore();
    props.history.push('/');

    MixpanelHelpers.registerMerchantConfig(props.merchantConfig);

    this.state = {
      payload: props.merchantPayload,
      isNumberOfInstallmentSelected: false,
    };

    if (PA) {
      if (PA.logoURL) {
        props.updateMerchantPairs({ logoURL: PA.logoURL });
      }

      if (PA.token) {
        props.updatePaymentPairs({ token: PA.token });
      }
    }

    if (!PA && props.merchantConfig && props.merchantConfig.key && props.merchantConfig.api_host) {
      console.log('Payment re-initialized.');

      apiKey = props.merchantConfig.key;
      apiHost = props.merchantConfig.api_host;

      PA = new PaymentAPI(
        apiKey,
        apiHost,
        props.merchantConfig.tier,
        props.merchantConfig.token,
        props.merchantConfig.logo_url
      );

      if (isToken(PA)) {
        MixpanelHelpers.registerMerchantConfigTier('token');
      }

      if (PA.tier) {
        props.updatePaymentPairs({ tier: PA.tier });

        MixpanelHelpers.registerMerchantConfigTier(PA.tier);
      }

      if (PA.token) {
        props.updatePaymentPairs({ token: PA.token });
      }

      if (PA.logoURL) {
        props.updateMerchantPairs({ logoURL: PA.logoURL });
      }
    }

    if (this.state.payload) {
      props.updatePaymentPairs({
        totalAmount: this.state.payload.amount,
      });

      MixpanelHelpers.registerOrderAmount(this.state.payload.amount);
      MixpanelHelpers.registerStoreName(this.state.payload.store_name);

      if (this.state.payload.store_name) {
        props.updateMerchantPairs({
          shopName: this.state.payload.store_name,
        });
      }

      // Save merchantId to identify the merchant
      if (apiKey) {
        props.updateMerchantPairs({
          keyId: apiKey,
        });
      }

      /**
       * if not DIGITAL payment && there's no locally saved credential
       * pre-fill email/phone with merchant provided buyer data
       * basically: local credential > user provided data > buyer data
       */
      const merchantBuyer = idx(this.state, _ => _.payload.buyer);

      if (!(props.email && props.phone) && merchantBuyer) {
        props.updateAuthPairs({
          email: typeof merchantBuyer.email === 'string' ? merchantBuyer.email : '',
          phone: typeof merchantBuyer.phone === 'string' ? merchantBuyer.phone : '',
          fromMerchant: true,
        });
      }
    }

    this.confirmToken = PA.confirmToken.bind(PA);
    this.patchToken = PA.patchToken.bind(PA);
    MixpanelHelpers.trackAction({
      customPath: 'Checkout',
      actionName: MIXPANEL_ACTION_APP_OPEN,
    });

    MixpanelHelpers.trackDuration({
      customPath: 'Checkout',
      actionName: MIXPANEL_ACTION_APP_OPEN,
    });
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload);
    window.addEventListener('resize', updateViewportOrientation);

    MixpanelHelpers.unregisterSuperProperties([
      'Merchant Config Tier',
      'Order Amount',
      'Store Name',
      'User Agent',
      'Viewport Orientation',
      'Viewport Width',
      'Viewport Height',
      'Viewport AspectRatio',
    ]);

    MixpanelHelpers.registerUserAgent();
    MixpanelHelpers.registerViewportOrientation();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  @autobind
  onCreate(email: string, phone: string): Promise<any> {
    const { payload } = this.state;
    const { tier } = this.props;

    /**
     * if buyer email provided by merchant is invalid:
     * - email: validated by RE
     * then override the values with user provided data
     * this is to prevent unexpected rejection
     * finally we send a extra [original_buyer_from_merchant] back to backend server, this is for API response to the merchants (TBD)
     */

    const buyer_data = payload.buyer_data || {};

    let checkoutPayload = {
      ...payload,
      consumer_data: {
        email,
        phone: getParsedPhoneNumber(phone),
      },
      tier,
      buyer_data,
    };

    try {
      checkoutPayload = {
        ...checkoutPayload,
        /**
         * TOFIX: this is a workaround for doc ambigious and merchants don't follow the specs
         */
        metadata: normalizeMetadata(checkoutPayload.metadata),
        order: {
          ...checkoutPayload.order,
          items: checkoutPayload.order.items.map(item =>
            Object.keys(item).reduce((accu, prop) => {
              const _accu = { ...accu };

              /**
               * TOFIX: this is a workaround for merchants don't follow the specs
               */
              if (['id', 'title', 'description'].indexOf(prop) > -1) {
                _accu[prop] = `${item[prop]}`;
              } else {
                _accu[prop] = item[prop];
              }

              return _accu;
            }, {})
          ),
        },
      };
    } catch (error) {
      // do nothing
    }

    const tokenPayload = {
      activation_method: 'pin',
      consumer_data: {
        email,
        phone: getParsedPhoneNumber(phone),
      },
      description: (PA.token && PA.token.description) || '',
      kind: (PA.token && PA.token.type) || 'recurring',
      metadata: payload.metadata || {},
      // for tokenPayload, we do not pass in buyer object
      // from merchant as with checkoutPayload
      origin: {
        address: idx(payload, _ => _.shipping_address),
        ...payload.buyer,
      },
      wallet_id: (PA.token && PA.token.wallet_id) || 'default',
      webhook_url: PA.token && PA.token.webhook_url,
    };

    const {
      updateAuthPairs,
      updatePaymentPairs,
      updateFormPairs,
      updateUIPairs,
      pinCodeSentCount,
    } = this.props;

    updateUIPairs({ isLoading: true });

    return PA.create(PA.token ? tokenPayload : checkoutPayload)
      .then(res => {
        if (res && res.data && res.data.merchant_tracking) {
          MixpanelHelpers.registerMerchantTracking(res.data.merchant_tracking);
        }

        updateUIPairs({ isLoading: false, pinCodeSentCount: pinCodeSentCount + 1 });

        const paymentId = idx(res, _ => _.data.id);
        const realTier = idx(res, _ => _.data.tier);
        const status = idx(res, _ => _.data.status);
        const shippingAddress = idx(checkoutPayload, _ => _.shipping_address);

        let nameKanji = idx(checkoutPayload, _ => _.buyer.name1);
        let nameKana = idx(checkoutPayload, _ => _.buyer.name2);

        nameKanji = nameKanji && nameKanji.split(/\s/);
        nameKana = nameKana && nameKana.split(/\s/);

        const newState: {
          status: ?string,
          email: string,
          phone: string,
          paymentId: ?string,
          tier?: string,
          currency: ?string,
          city: ?string,
          line1: ?string,
          line2: ?string,
          state: ?string,
          zip: ?string,
          date_of_birth: string,

          first_name_kana: ?string,
          first_name_kanji: ?string,
          last_name_kana: ?string,
          last_name_kanji: ?string,
        } = {
          status,
          email,
          phone,
          paymentId,
          currency: PA.token ? undefined : checkoutPayload.currency,
          city: idx(shippingAddress, _ => _.city),
          line1: idx(shippingAddress, _ => _.line1),
          line2: idx(shippingAddress, _ => _.line2),
          state: idx(shippingAddress, _ => _.state),
          zip: idx(shippingAddress, _ => _.zip),
          date_of_birth: idx(checkoutPayload, _ => _.buyer.date_of_birth) || '',
          first_name_kana: idx(nameKana, _ => _[1]),
          first_name_kanji: idx(nameKanji, _ => _[1]),
          last_name_kana: idx(nameKana, _ => _[0]),
          last_name_kanji: idx(nameKanji, _ => _[0]),
        };

        if (realTier) {
          newState.tier = realTier;

          MixpanelHelpers.registerMerchantConfigTier(realTier);
        } else if (PA.token) {
          MixpanelHelpers.registerMerchantConfigTier('token');
        }

        updateAuthPairs({
          email: newState.email,
          phone: newState.phone,
        });

        updatePaymentPairs({
          status: newState.status,
          paymentId: newState.paymentId,
          createdAt: idx(res, _ => _.data.created_at),
          tier: realTier || undefined,
          currency: newState.currency,
        });

        const reDateOfBirth = /\d{4}-\d{2}-\d{2}/;
        const isValidDOB = reDateOfBirth.test(newState.date_of_birth || '');

        const formPairs: {
          city: ?string,
          line1: ?string,
          line2: ?string,
          state: ?string,
          zip: ?string,
          date_of_birth: ?string,

          first_name_kana: ?string,
          first_name_kanji: ?string,
          last_name_kana: ?string,
          last_name_kanji: ?string,
        } = {
          city: newState.city,
          line1: newState.line1,
          line2: newState.line2,
          state: newState.state,
          zip: newState.zip,
          date_of_birth: isValidDOB ? newState.date_of_birth : '',
          first_name_kana: newState.first_name_kana,
          first_name_kanji: newState.first_name_kanji,
          last_name_kana: newState.last_name_kana,
          last_name_kanji: newState.last_name_kanji,
        };

        updateFormPairs(formPairs);

        return res;
      })
      .catch((err: Object) => {
        updateUIPairs({ isLoading: false });

        return Promise.reject(err);
      });
  }

  @autobind
  onAuthenticate(pinCode: string): Promise<any> {
    const { updateMerchantPairs, updatePaymentPairs, updateUIPairs } = this.props;

    updateUIPairs({ isLoading: true });

    const userAgent = navigator.userAgent || '';

    MixpanelHelpers.addSessionData({
      Webview: isWebView(userAgent) ? 'TRUE' : 'FALSE',
    });

    return PA.authenticate(pinCode, isWebView(userAgent) || !isWithinCICBusinessHours()).then(
      res => {
        updateUIPairs({ isLoading: false });

        const authStatus = idx(res, _ => _.data.status);

        const configuration = idx(res, _ => _.data.configuration);
        const installmentOptions = idx(configuration, _ => _.installment_options);
        const installmentPlanOptions = idx(configuration, _ => _.installment_plan_options);
        const multiPayOffered = !!installmentOptions && installmentOptions.length > 1;
        const threePayOffered =
          !!installmentPlanOptions &&
          installmentPlanOptions.available &&
          installmentPlanOptions.available.length;
        const requireBigContractData = idx(configuration, _ => _.required_big_contract_data);
        const isBig = !!requireBigContractData && requireBigContractData !== 'none';
        // FIXME: ask platform team to return boolean instead
        const isFirstTime = idx(configuration, _ => _.first_time_user) === 'true';

        const newState = {};

        newState.isBig = isBig;

        if (installmentOptions && installmentOptions.length) {
          newState.installmentOptions = installmentOptions;
          newState.multiPayOffered = multiPayOffered;
        }

        if (threePayOffered) {
          newState.installmentPlanOptions = installmentPlanOptions;
          newState.threePayOffered = threePayOffered;
        }

        newState.isFirstTime = isFirstTime;
        newState.isTest = idx(res, _ => _.data.test);

        updatePaymentPairs({
          ...newState,
          configuration,
          status: authStatus,
        });

        // Checks if there are conditions required to get the payment approved with the installment plan option stolen
        // Primarily used for high-ticket merchants that allow Classic consumers to purchase products above their
        // credit limit, but requires them to upgrade their accounts to Plus in order to get approved
        const installmentPlanOptionsCondition = idx(
          configuration,
          _ => _.installment_plan_options_condition
        );

        if (
          installmentPlanOptionsCondition &&
          installmentPlanOptionsCondition === 'kyc' &&
          authStatus !== paymentStatusMap.PENDING
        ) {
          updateMerchantPairs({
            isHighTicket: true,
          });
        }

        if (
          authStatus === paymentStatusMap.PENDING &&
          installmentPlanOptionsCondition &&
          installmentPlanOptionsCondition === 'kyc'
        ) {
          MixpanelHelpers.addSessionData({
            'Plus Upgrade': 'TRUE',
          });
          updateMerchantPairs({
            isRequiredPlusUpgrade: true,
          });
          updatePaymentPairs({
            selectionOptionPhase: SELECTION_OPTION_PHASE.PRESELECT,
          });
        }

        const trackingId = idx(res, _ => _.data.external_consumer_ids.tracking);

        if (trackingId) {
          MixpanelHelpers.userProfileIdentify(trackingId);
          updatePaymentPairs({
            trackingId: trackingId,
          });
        }

        return Promise.resolve(res);
      },
      err => {
        updateUIPairs({ isLoading: false });

        const errorCode = idx(err, _ => _.data.code);

        if (errorCode === ERROR_WRONG_PINCODE) {
          console.error('Wrong pincode.');
        } else if (Object.values(errorCodecV2).includes(err.data.code)) {
          updatePaymentPairs({ status: paymentStatusMap.REJECTED });
        } else {
          updatePaymentPairs({ status: 'failed' });
        }

        return Promise.reject(err);
      }
    );
  }

  /**
   * This method is designed to execute before we do real patch request.
   *
   * The main purpose is to see do we have everything required to patch the payment.
   * If not, navigate to the form.
   *
   * The ideal structure is execute this method when we got response back from API.
   * But to minimize the change. We only do this before patch. Then we can keep most
   * of our stable logic.
   */
  @autobind
  onPrePatch(payload: any): Promise<any> {
    const { configuration, updatePaymentPairs, history } = this.props;
    const {
      required_consumer_data: requiredConsumerData,
      installment_plan_options: requireInstallmentPlanOptions,
    } = configuration || {};

    const availablePlanOptions = idx(requireInstallmentPlanOptions, _ => _.available) || [];

    // Only enable this method for digital first 3pay flow for now
    if (!(requiredConsumerData && availablePlanOptions.length)) {
      return Promise.resolve(payload);
    }

    updatePaymentPairs({
      patch: payload,
    });

    // We do simple check, not check every prop
    if (requiredConsumerData && !payload.consumer_data) {
      history.push('/digital/form');

      return Promise.reject();
    }

    if (
      availablePlanOptions.length &&
      !(payload.installment_plan_kind || payload.number_of_installments)
    ) {
      history.push('/3pay/select');

      return Promise.reject();
    }

    return Promise.resolve(payload);
  }

  @autobind
  onPatch(config: any): Promise<any> {
    const { updateUIPairs, patch } = this.props;
    const uiLoadingConfig: { isLoading: boolean, loadingType?: string } = { isLoading: true };

    const payload = { ...(patch || {}), ...config };

    return (
      this.onPrePatch(payload)
        // Reject means we have other form need to go.
        // Convert to resolve because we don't want go to error screen
        .catch(() => Promise.resolve())
        .then((preparedPayload: any) => {
          // If the payload is undefined, we are going to other screen, resolve immediately.
          if (!preparedPayload) {
            return Promise.resolve();
          }

          if (preparedPayload.big_contract_data) {
            uiLoadingConfig.loadingType = loadingTypes.BIG;
          }

          updateUIPairs(uiLoadingConfig);

          const userAgent = navigator.userAgent || '';
          preparedPayload.ineligibleForUpgrade =
            isWebView(userAgent) || !isWithinCICBusinessHours();

          return PA.patch(preparedPayload)
            .then(res => {
              updateUIPairs({ isLoading: false, loadingType: loadingTypes.REGULAR });

              // TODO: Merge this logic to the screens, this code is redundant
              // This is usually used for determining which screen to show next
              this.props.updatePaymentPairs({
                status: idx(res, _ => _.data.status),
              });

              return res;
            })
            .catch(err => {
              updateUIPairs({ isLoading: false });

              if (Object.values(errorCodecV2).includes(err.data.code)) {
                this.props.updatePaymentPairs({ status: paymentStatusMap.REJECTED });
              }

              return Promise.reject(err);
            });
        })
    );
  }

  @autobind
  onFetchStatus(): Promise<any> {
    return PA.fetchStatus().then(
      res => {
        const authStatus = idx(res, _ => _.data.status);
        const configuration = idx(res, _ => _.data.configuration);
        const installmentOptions = idx(configuration, _ => _.installment_options);
        const installmentPlanOptions = idx(configuration, _ => _.installment_plan_options);

        const newState = {};
        newState.installmentOptions = installmentOptions;
        newState.installmentPlanOptions = installmentPlanOptions;

        this.props.updatePaymentPairs({
          ...newState,
          configuration,
          status: authStatus,
        });

        return Promise.resolve(res);
      },
      err => {
        if (Object.values(errorCodecV2).includes(err.data.code)) {
          this.props.updatePaymentPairs({ status: paymentStatusMap.REJECTED });
        } else {
          this.props.updatePaymentPairs({ status: 'failed' });
        }

        return Promise.reject(err);
      }
    );
  }

  render() {
    if (!PA || !this.state.payload) {
      return null;
    }

    const { isNumberOfInstallmentSelected } = this.state;

    return (
      <Switch>
        <Route exact path="/" render={() => <FirstScreen onCreate={this.onCreate} />} />
        <Route exact path="/intro" render={() => <Intro tier={this.props.tier} />} />
        <Route
          path="/error/:type?"
          render={props => (
            <Error
              {...props}
              merchantConfig={this.props.merchantConfig}
              merchantPayload={this.props.merchantPayload}
            />
          )}
        />
        <Route path="/plus/:phase?" render={() => <Plus onPatch={this.onPatch} />} />
        <Route path="/high-ticket" render={() => <HighTicket />} />
        <Route exact path="/big" component={Big} />
        <Route exact path="/big/single" render={() => <BigSingle onPatch={this.onPatch} />} />
        <Route
          exact
          path="/digital/form"
          render={() => <Digital onPatch={this.onPatch} patchToken={this.patchToken} />}
        />
        <Route
          exact
          path="/token/confirm"
          render={() => <TokenConfirm confirmToken={this.confirmToken} />}
        />
        <Route exact path="/toc" component={TOC} />
        <Route exact path="/help" component={Help} />
        <Route
          exact
          path="/login"
          render={({ location }) => <LoginForm location={location} onSubmit={this.onCreate} />}
        />
        <Route
          exact
          path="/otp"
          render={() => (
            <OTPInput
              onCreate={this.onCreate}
              onGetCodeThruVoice={onGetCodeThruVoice}
              digits={4}
              onAuthenticate={this.onAuthenticate}
              apiKey={apiKey}
              isToken={isToken(PA)}
            />
          )}
        />
        <Route
          path="/multi-pay/:phase?"
          render={({ location }) => (
            <Multipay
              // passing location into MultiPay is to prevent update blocked by connect
              location={location}
              onPatch={this.onPatch}
              isNumberOfInstallmentSelected={isNumberOfInstallmentSelected}
            />
          )}
        />
        <Route path="/3pay/:phase?" render={() => <Threepay onPatch={this.onPatch} />} />
        <Route
          exact
          path="/result/:type?"
          render={props => (
            <Result
              {...props}
              merchantConfig={this.props.merchantConfig}
              merchantPayload={this.props.merchantPayload}
            />
          )}
        />
        {/* Plus Uprade in Checkout */}
        <Route
          path="/plus-upgrade/pending"
          render={props => <PlusUpgradePending onFetchStatus={this.onFetchStatus} {...props} />}
        />
      </Switch>
    );
  }
}

const AppWithRouter = withRouter(App);

const RoutedApp = (props: Props) => (
  <div className={styles.wrapper}>
    <div id="main-app-content-height" className={styles.content}>
      <ErrorBoundary>
        <Router>
          <AppWithRouter {...props} />
        </Router>
      </ErrorBoundary>
    </div>
  </div>
);

export default RoutedApp;
