// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
// components
import FrameHeader from './FrameHeaderContainer';
import FrameFooter from './FrameFooter';
import Loading from '../Loading/Loading';
// context and utils
import styles from './Frame.scss';

type Props = {
  children?: any,
  hasCornerBackButton?: boolean,
  hasCornerCloseButton?: boolean,
  hasHeader?: boolean,
  hasFooter?: boolean,
  helpType?: ?string,
  isLoading: boolean,
  showInstallments?: boolean,
  hideShopInfo?: boolean,
  footerText?: string,
  footerHref?: string;
  footerOnClick?: () => void,
};

const Frame = ({
  children,
  hasCornerBackButton,
  hasCornerCloseButton,
  hasHeader,
  hasFooter,
  helpType,
  isLoading,
  showInstallments,
  hideShopInfo,
  footerText,
  footerHref,
  footerOnClick,
}: Props): React$Node => {
  return (
    <div className={styles.wrapper}>
      {hasHeader && (
        <FrameHeader
          hasCornerBackButton={!isLoading && hasCornerBackButton}
          hasCornerCloseButton={!isLoading && hasCornerCloseButton}
          showInstallments={showInstallments}
          hideShopInfo={hideShopInfo}
        />
      )}

      <main className={styles.main}>{isLoading ? <LoadingSwitch /> : children}</main>

      {!isLoading && hasFooter && (
        <FrameFooter footerOnClick={footerOnClick} footerHref={footerHref} footerText={footerText} helpType={helpType} />
      )}
    </div>
  );
};

const LoadingSwitch = () => {
  return <Loading />;
};

Frame.defaultProps = {
  hasCornerBackButton: true,
  hasCornerCloseButton: true,
  hasHeader: true,
  hasFooter: true,
  children: null,
  helpType: undefined,
  isLoading: false,
  showInstallments: false,
  hideShopInfo: false,
};

export default withRouter(Frame);
