// @flow
import idx from 'idx';
import japanPostalCode from 'japan-postal-code';
import debounce from 'lodash.debounce';
import * as Sentry from '@sentry/browser';
// FIXME: replace google-libphonenumber with a ligher lib
import { isMobileOnly } from 'react-device-detect';
import GooglePhoneNumber from 'google-libphonenumber';
import { store } from '../redux/modules';
import { installmentTypeMap, tierMap, paymentStatusMap } from '../constants';
import {
  tierTypes,
  requiredBigContractDataTypes,
  requiredConsumerDataTypes,
  plusFormTypes,
  authStatusTypes,
  installmentTypes,
} from '../types';
import parent from './parent';
import { errorCodecV2 } from '../constants/error-codes';

declare var document: {
  ...Document,
  msHidden: string,
  webkitHidden: string,
  addEventListener: Function,
};

export const normalizeMetadata = (metadata: any): Object => {
  const normalized = {};

  if (typeof metadata === 'object') {
    Object.keys(metadata).forEach(key => {
      normalized[key] = metadata[key].toString();
    });
  }

  return normalized;
};

export const isFunction = (fn: Function): boolean =>
  fn && {}.toString.call(fn) === '[object Function]';

// TODO: Merge `getTierAndTypeFromAuthResponse` with `App.js` `onAuthenticate` function
// Lots of duplicated code here
export const getTierAndTypeFromAuthResponse = (
  res: Object,
  isToken: boolean
): {
  tier: ?tierTypes,
  installmentType: installmentTypes,
  formType?: plusFormTypes,
  authStatus: ?authStatusTypes,
  requiredConsumerData?: ?requiredConsumerDataTypes,
  requiredBigContractData?: ?requiredBigContractDataTypes,
  highTicket: boolean,
  requirePlusUpgrade: boolean,
} => {
  const tier = idx(res, _ => _.data.tier);
  const authStatus = idx(res, _ => _.data.status);
  const requiredBigContractData: ?string = idx(
    res,
    _ => _.data.configuration.required_big_contract_data
  );
  const installmentOptions: ?Array<any> = idx(res, _ => _.data.configuration.installment_options);
  const installmentPlanOptions: any = idx(res, _ => _.data.configuration.installment_plan_options);
  const requiredConsumerData: ?requiredConsumerDataTypes = idx(
    res,
    _ => _.data.configuration.required_consumer_data
  );

  // Checks if there are conditions required to get the payment approved with the installment plan option stolen
  // Primarily used for high-ticket merchants that allow Classic consumers to purchase products above their
  // credit limit, but requires them to upgrade their accounts to Plus in order to get approved
  const installmentPlanOptionsCondition = idx(
    res,
    _ => _.data.configuration.installment_plan_options_condition
  );
  const highTicket = installmentPlanOptionsCondition && installmentPlanOptionsCondition === 'kyc';

  const requirePlusUpgrade =
    authStatus === paymentStatusMap.PENDING &&
    installmentPlanOptionsCondition &&
    installmentPlanOptionsCondition === 'kyc';

  const multiPayOffered = installmentOptions && installmentOptions.length;
  const threePayOffered =
    installmentPlanOptions &&
    installmentPlanOptions.available &&
    installmentPlanOptions.available.length;

  // eslint-disable-next-line no-nested-ternary
  const defaultInstallmentType = threePayOffered
    ? installmentTypeMap.THREEPAY
    : multiPayOffered
    ? installmentTypeMap.MULTI
    : installmentTypeMap.SINGLE;

  // token
  if (isToken) {
    return {
      tier,
      installmentType: installmentTypeMap.TOKEN,
      authStatus,
      requiredConsumerData,
    };
  }

  // big
  if (tier === tierMap.CLASSIC && requiredBigContractData) {
    return {
      tier,
      requiredBigContractData,
      installmentType: defaultInstallmentType,
      authStatus,
    };
  }

  // plus
  const requiredPlusContractData = idx(res, _ => _.data.configuration.required_plus_contract_data);

  if (requiredPlusContractData && requiredPlusContractData !== 'none') {
    return {
      tier,
      installmentType: 'single',
      formType: requiredPlusContractData,
      authStatus,
    };
  }

  // classic || digital
  const installmentType = defaultInstallmentType;

  return {
    tier,
    installmentType,
    authStatus,
    requiredConsumerData,
    highTicket,
    requirePlusUpgrade,
  };
};

export const isToken = (config: Object) => !!config.token && config.token.type === 'recurring';

export const errorRoute = (err: Object, history: Object): void => {
  switch (err.status) {
    case 400: {
      switch (err.data.code) {
        case 'consumer.email.mismatch':
        case 'consumer.phone.mismatch':
        case 'consumer.previously-rejected': {
          const subRoute = err.data.code.split('.').join('-');

          history.push(`/error/${subRoute}`);
          break;
        }
        default:
          break;
      }

      break;
    }
    case 500: {
      break;
    }
    default:
      break;
  }
};

// When a payment is marked as PENDING, Checkout can invoke a callback so merchants can do something about it.
// Originally designed for high-ticket merchants, where a payment can be marked pending if the consumer has not gone
// through the eKYC process.
export const onPending = (payload: any) => {
  console.log('Paidy Checkout: Payment pending.');

  parent.postMessage({
    event: 'paidy_checkout:payment_pending',
    payload,
  });
};

export const onClose = (showConfirmation = false, isPlusUpgrade = false): void => {
  const before = new Date().getTime();
  // WKWebView confirm might not able to show and return `false` directly.
  // Require a time delay to confirm its user interactive, or we just close the app.
  const CONFIRM_THRESHOLD = 50;

  /* eslint-disable */
  if (
    showConfirmation &&
    !window.confirm('閉じるとお支払いが中断されます。\nそれでもよろしいですか？') &&
    new Date().getTime() - before > CONFIRM_THRESHOLD
  ) {
    return;
  }
  /* eslint-enable */

  console.log('Paidy Checkout: Checkout closed.');

  const state = store.getState();

  parent.postMessage({
    event: 'paidy_checkout:close',
    payload: {
      amount: idx(state, _ => _.payment.totalAmount),
      created_at: idx(state, _ => _.payment.createdAt),
      currency: idx(state, _ => _.payment.currency),
      id: idx(state, _ => _.payment.paymentId),
      // If the payment is pending and Plus Upgrade, then the status is closed not pending
      status: idx(state, _ => _.payment.status) === paymentStatusMap.PENDING && isPlusUpgrade ? 'closed' : idx(state, _ => _.payment.status), 
      test: idx(state, _ => _.payment.isTest),
    },
  });
};

export const zenToHan = (zen: string) =>
  zen.replace(/[Ａ-Ｚａ-ｚ０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xfee0));

export const hanToZen = (han: string) =>
  han.replace(/[A-Za-z0-9]/g, s => String.fromCharCode(s.charCodeAt(0) + 0xfee0));

export const setValueByIndex = (_idx: number, value: string, arr: Array<string>): Array<string> => {
  const newArr: Array<string> = arr.slice();

  newArr[_idx] = zenToHan(value);

  return newArr;
};

export const toHalfWidth = str => {
  str = str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
  return str;
};

const errorCodecV2List = Object.values(errorCodecV2);

export const errorHandler = ({ err, history }: { err: Object, history: Object }): void => {
  let errorCode = idx(err, _ => _.data.code) || idx(err, _ => _.code);

  if (errorCode) {
    if (typeof errorCode !== 'string') {
      const rawErrorCode = errorCode;

      errorCode = errorCode.toString();

      Sentry.withScope(scope => {
        scope.setExtra('errorCode', rawErrorCode);
        Sentry.captureException(new TypeError("Type of 'errorCode' is not a string."));
      });
    }

    if (errorCode.indexOf('.') > -1 && errorCodecV2List.indexOf(errorCode) === -1) {
      errorCode = errorCode.split('.').join('-');
    }

    history.push(`/error/${errorCode}`);
  } else {
    history.push('/error');
  }
};

export const getParsedPhoneNumber = (phone: ?string): string => {
  if (!phone) {
    return '';
  }

  const PNF = GooglePhoneNumber.PhoneNumberFormat;
  const phoneUtil = GooglePhoneNumber.PhoneNumberUtil.getInstance();

  // parse first
  try {
    const parsedRawPhoneNumber = phoneUtil.parseAndKeepRawInput(phone, 'JP');

    const number = phoneUtil.isValidNumber(parsedRawPhoneNumber);

    if (
      !number ||
      // Lock to Japan only
      parsedRawPhoneNumber.getCountryCode() !== 81 ||
      // Mobile phone numbers (with no prefixes) are 10-digits long
      parsedRawPhoneNumber.getNationalNumber().toString().length !== 10
    ) {
      console.log('Paidy Checkout: The phone format is incorrect, please try again.');

      return phone;
    }

    return phoneUtil.format(parsedRawPhoneNumber, PNF.E164).replace('+', '');
  } catch (error) {
    console.error(
      'Paidy Checkout: Unable to parse the phone number with unexpected format, maybe it is too long? Using the original input phone number.'
    );

    return phone;
  }
};

export const isValidPhoneNumber = (phone: ?string) => {
  if (!phone) {
    return false;
  }

  try {
    const phoneUtil = GooglePhoneNumber.PhoneNumberUtil.getInstance();
    const parsedRawPhoneNumber = phoneUtil.parseAndKeepRawInput(phone, 'JP');

    return phoneUtil.isValidNumber(parsedRawPhoneNumber);
  } catch (error) {
    console.error(
      "Unable to parse the phone number with unexpected format, maybe it's too long? Regarding it as invalid phone number."
    );

    return false;
  }
};

export const parsePhoneNumber = (phone: ?string) => {
  if (!phone) {
    return phone;
  }

  const phoneUtil = GooglePhoneNumber.PhoneNumberUtil.getInstance();
  const PNF = GooglePhoneNumber.PhoneNumberFormat;
  const number = phoneUtil.parse(phone, 'JP');

  if (!phoneUtil.isValidNumber(number)) {
    return phone;
  }

  const result = phoneUtil.formatInOriginalFormat(number, PNF.NATIONAL).replace(/-/g, '');

  return `${result.slice(0, 3)} ${result.slice(3, 7)} ${result.slice(7)}`;
};

export const isValidConfig = (payload: Object): boolean => {
  /**
   * api_key: string (required)
   * logo_url: string (optional)
   * closed: Function (optional)
   * metadata: Object (optional)
   */
  if (!payload.apiKey) {
    console.error('api_key is required.');

    return false;
  }

  if (!/pk_(test|live)_([\w\d]{26})/.test(payload.api_key)) {
    console.error(
      "There's something wrong with your api_key, please double check. Maybe it's a typo?"
    );

    return false;
  }

  return true;
};

/**
 * amount: number (required)
 * currency: string (required)
 * buyer: Object (required)
 * buyer_data: Object (required)
 * order: Object (required)
 * shipping_address: Object (required)
 * store_name: string (optional)
 * description: string (optional)
 * metadata: Object (optional)
 */
export const isValidPayload = (payload: Object) =>
  payload.amount &&
  payload.currency &&
  payload.buyer &&
  payload.buyer_data &&
  payload.order &&
  payload.shipping_address;

export const getAge = (dateString: string) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  const m = today.getMonth() - birthDate.getMonth();
  let age = today.getFullYear() - birthDate.getFullYear();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }

  return age;
};

// toString is to make sure unexpected input never cause issue
export const getFormattedZip = (zip: string) => zip.toString().replace(/\D/g, '');

export const isLocalStorageSupported = (): boolean => {
  if (typeof isLocalStorageSupported.result !== 'undefined') {
    return isLocalStorageSupported.result;
  }

  const mod = 'modernizr';

  try {
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);

    isLocalStorageSupported.result = true;
  } catch (error) {
    isLocalStorageSupported.result = false;
  }

  return isLocalStorageSupported.result;
};

export const getDesktopContainerStyle = (height: number): ?Object => {
  const maxHeight = window.innerHeight * 0.9;

  return isMobileOnly ? undefined : { height: Math.min(height, maxHeight - 136) };
};

export const scrollToFirstError = () => {
  setTimeout(() => {
    try {
      // $FlowFixMe
      const firstErrorEl = document.querySelector('.error');

      // $FlowFixMe
      document.getElementById('real_scrollbar').childNodes[0].scrollTop =
        firstErrorEl.offsetTop + 80;
    } catch (error) {
      // do nothing
    }
  }, 0);
};

const ZIP_REQ_DELAY = 250;

export const debouncedZipReq = debounce(({ updateFormPairs, value }) => {
  try {
    japanPostalCode.get(value, (address: Object) => {
      if (address) {
        updateFormPairs({
          state: address.prefecture,
          city: `${address.city}${address.area}`,
          /**
           * We remove the following fields
           * because the users intend to change the line1 and line2
           */
          line1: '',
          line2: '',
        });
      }
    });
  } catch (error) {
    Sentry.captureException(error);
  }
}, ZIP_REQ_DELAY);

/**
 * @param {?string} url The URL to check
 * @return Return the URL if its absolute or protocol relative URL
 *         Otherwise, return false.
 */
export const absoluteURL = (url: ?string): string => {
  if (typeof url === 'string' && /^(https?:\/\/|\/\/)/.test(url)) {
    return url;
  }

  return '';
};

export const isWebView = (userAgent: ?string): boolean => {
  // Check for Android WebView
  if (/wv/.test(userAgent)) {
    return true;
  }
  // Check for iOS WebView
  if (/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/.test(userAgent)) {
    return true;
  }
  if (/ymd(\/)\d+.\d+.\d+/.test(userAgent)) {
    return true;
  }

  return false;
};

const getTimeDateJST = timeStamp => {
  const formatter = Intl.DateTimeFormat('en-US', {
    timeZone: 'Asia/Tokyo',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });

  return formatter.formatToParts(timeStamp).reduce((acc, part) => {
    if (part.type !== 'literal') {
      acc[part.type] = part.value;
    }
    return acc;
  }, {});
};

// OUTSIDE CIC Hours for Plus Upgrade
// Mon - Sat: 24:29 to 07:59
// Sun: 21:29 to 07:59
export const isWithinCICBusinessHours = () => {

  // TODO: Backend should handle the logic, since JavaScript just depends on the device's system time
  let { weekday, hour, minute } = getTimeDateJST(Date.now());

  if (parseInt(hour) > 7 || parseInt(hour) === 0) {
    if (weekday === 'Sun') {
      if (parseInt(hour) === 21 && parseInt(minute) > 28) {
        return false;
      }
      return hour <= 21;
    }

    if (parseInt(hour) === 0 && parseInt(minute) > 28) {
      return false;
    }

    return hour <= 24;
  }

  return false;
};

export { parent };

export default {
  isFunction,
  isToken,
  getTierAndTypeFromAuthResponse,
  errorRoute,
  onClose,
  setValueByIndex,
  errorHandler,
  isValidPhoneNumber,
  getFormattedZip,
  isLocalStorageSupported,
  scrollToFirstError,
  debouncedZipReq,
  zenToHan,
  hanToZen,
  isWebView,
  isWithinCICBusinessHours,
};
