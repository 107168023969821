// @flow
import React from 'react';
import styles from './Error.scss';

type Props = {
  title: string,
  description: string[],
  onClick: () => void,
  buttonText?: string,
  buttonHref: string,
  hasFooter: boolean,
  bulletedLists?: string[],
  noteText?: string[],
  bottomDescription?: string[],
  bottomAsset: any,
  body: any[],
};

export default function ErrorContentMobile({
  title,
  description,
  onClick,
  buttonText,
  buttonHref,
  hasFooter,
  bulletedLists,
  noteText,
  bottomDescription,
  bottomAsset,
  body,
}: Props) {
  return (
    <>
      {body ? (
        <div className={styles['scrollable-content']}>{body}</div>
      ) : (
        <div className={styles['scrollable-content']}>
          <b data-testid="error-screen-title" className={styles.title}>
            {title}
          </b>
          {description.map(text => (
            <p key={text} className={styles['description']}>
              {text}
            </p>
          ))}
          {bulletedLists
            ? bulletedLists.map(bulletedText => (
                <div className={styles['bulleted-item']} key={bulletedText}>
                  <img src={require('../../assets/bulleted-dark.svg')} alt="bulleted icon" />
                  <span>{bulletedText}</span>
                </div>
              ))
            : null}
          {noteText ? (
            <div className={styles['notification-text']}>
              {noteText.map(text => (
                <p key={text}>{text}</p>
              ))}
            </div>
          ) : null}

          {bottomDescription
            ? bottomDescription.map(text => (
                <p key={text} className={styles['description']}>
                  {text}
                </p>
              ))
            : null}
          {bottomAsset ? (
            <img className={styles['bottom-image']} src={bottomAsset} alt="bulleted icon" />
          ) : null}
        </div>
      )}

      {buttonText ? (
        <div className={`${styles.controls} ${!hasFooter ? styles['controls-no-footer'] : ''}`}>
          {buttonHref ? (
            <a
              className={`${styles.button} ${styles['button-link']}`}
              onClick={onClick}
              target="_blank"
              data-testid="btn-close"
              href={buttonHref}
              rel="noreferrer"
            >
              {buttonText}
            </a>
          ) : (
            <button className={`${styles.button}`} onClick={onClick} data-testid="btn-close">
              {buttonText}
            </button>
          )}
        </div>
      ) : null}
    </>
  );
}
